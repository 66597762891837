import React from 'react';
import { WidthdrawTokenApprove } from '@/artwork/components/artwork-collections/withdraw-token/withdraw-token-approve';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { useTranslation } from '@/common/hooks';
import { jsx as ___EmotionJSX } from "@emotion/core";

var WithdrawTokenApprovePage = function WithdrawTokenApprovePage() {
  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: t('tokenWithdraw.title'),
    meta: [{
      name: 'robots',
      content: 'noindex'
    }]
  }), ___EmotionJSX(WidthdrawTokenApprove, null));
};

WithdrawTokenApprovePage.displayName = "WithdrawTokenApprovePage";
export { WithdrawTokenApprovePage as default };