import _styled from "@emotion/styled";

/**
 * @deprecated use `@emotion/styled` directly
 */
var styled = _styled;
export {
/**
 * @deprecated use `@emotion/styled` directly
 */
styled };